import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Button } from "components/anti/buttons/buttons"
import { Link } from "components/anti/link/link"

import logoLight from "assets/img/logo.svg"
import downloadBanner from "assets/img/donwload.jpg"
import downloadBannerMobile from "assets/img/donwload-mobile.jpg"

const DownloadPage = () => {
  return (
    <Layout>
      <Seo title="Beranda" />
      <section className="py-main">
        <div className="container d-flex justify-content-center">
          <Link to="https://drive.google.com/file/d/1DqtRbKzhi-yuWu5NZSA2p_-65WvZ0C1B/view">
            <img
              src={downloadBannerMobile}
              alt="Dompet aman"
              className="img-fluid"
            />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default DownloadPage
